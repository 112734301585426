$box-width: 250px;
$box-height: 174px;

$mid-grey: #ccc;
$border-radius: 4px;
$blue: $brand-primary;
$light-grey-text: #ccc;
$dark-grey: #ccc;

form.dropzone {
  border: 0;
  padding: 15px 0;

  .preview-container {
    display: inline;
  }

  .dz-preview {
    width: $box-width;
    height: 220px;
    border: 2px solid $mid-grey;
    border-radius: $border-radius;
    position: relative;
    margin: 0 15px 15px 0;
    padding: 5px 5px 0 5px;
    opacity: 1;
    transition: opacity 1.5s;

    .dz-details {
      opacity: 1;
      padding: 0;
      overflow: hidden;
      height: $box-height;
      position: static;
      min-width: 0;
      width: 100%;
      position: relative;

      .dz-error-msg {
        position: absolute;
        display: block;
        top: 50%;
        left: 0;
        right: 0;
        color: $brand-danger;
        background-color: rgba(255,255,255,0.8);
        transform: translateY(-50%);
        padding: 5px;
        display: none;
      }

      img {
        height: auto;
        max-height: 100%;
        max-width: 100%;
        opacity: 0.4;
      }
    }

    &.dz-success {
      img {
        opacity: 1;
      }
      .options {
        display: block;
      }
    }

    &.dz-error {
      .dz-error-msg {
        display: block;
      }
    }

    .dz-progress {
      margin-top: -18px;
    }

    .options {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      font-size: 14px;
      font-weight: 300;
      padding: 0 5px 8px 5px;
      text-align: center;
      color: $brand-primary;
      display: none;

      .options-inner {
        display: inline;
      }

      * {
        vertical-align: bottom;
        display: inline;
      }

      i {
        font-size: 20px;
      }

      .rotate-text {
        display: inline-block;
        padding: 0 10px;
        line-height: 19px;
      }

      .sep {
        height: 20px;
        background-color: $mid-grey;
        width: 1px;
        display: inline-block;
        margin: 0 10px;
      }
    }
  }

  .add {
    border: 2px dashed $mid-grey;
    cursor: pointer;
    &:after {
      font-family: Roboto;
      content: '+';
      display: block;
      text-align: center;
      line-height: 200px;
      font-size: 60px;
      font-weight: 100;
      color: $blue;
    }

    .add-footer {
      color: $light-grey-text;
      text-align: left;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px 20px;
      line-height: 20px;

      .count {
        font-weight: 500;
        font-size: 20px;
      }

    }
  }

  .thumb.rotate90 {
    transform: translateX(-50%) translateY(-50%) rotate(90deg);
    max-width: $box-height;
    max-height: 236px;
  }

  .thumb.rotate180 {
    transform: translateX(-50%) translateY(-50%) rotate(180deg);
  }

  .thumb.rotate270 {
    transform: translateX(-50%) translateY(-50%) rotate(270deg);
    max-width: $box-height;
    max-height: 236px;
  }

  .thumb {
    max-width: 100%;
    max-height: $box-height;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

}




#cropper {
  display: none;
  overflow: visible;
  width: 900px;
  // height: 700px;
  max-height: calc(100vh - 100px);
  max-width: calc(100vw - 100px);

  .cropper-header {
    margin: 15px;
    h2 { margin-top: 0}
  }

  .image {
    text-align: center;
    max-height: 500px;
    img {
      //      max-width: 100%;
      max-height: calc(100vh - 200px);
      max-width: calc(100vw - 200px);
      height: 500px;
      width: 100%;
    }
  }
}



.drop-here {
  display: none;
  font-size: 25px;
  font-weight: 300;
  color: $dark-grey;
  top: 50%;
  left: 0;
  right: 0;
  position: absolute;
  transform: translateY(-50%);
}

form.dropzone.dz-drag-hover {
  border: 2px dashed $mid-grey;
  margin-top: 20px;
  position: relative;
  height: 300px;

  &.dz-drag-hover {
    border-style: dashed;
  }

  .dz-preview {
    display: none;
  }
  .drop-here {
    display: block;
  }
}