.fc {
  margin-bottom: $gap;
}

.fc-event-container {
  .fc-day-grid-event {
    font-size: 13px;
    padding: 1px 2px;
  }
}

.fc-no-tickets {
  background-color: transparent;
  color: $text-color;
}

.fc-highlight {
  opacity: .6;
  filter: alpha(opacity=60); /* for IE */
}

.fa.telephone-booking {
  color: #333;
}

.availability-key:not(.availability-key-horizontal) .panel-body {
  @media (min-width: $screen-lg-min) {
    .row > div + div > div {
      border-top: 1px solid #ccc;
    }
  }
}

.availability-key-horizontal {
  .availability-key {
    margin-bottom: 15px;
  }
}

.availability-key {
  margin-bottom: 0;

  .panel-body {
    font-size: 13px;
    padding: 10px;

    // Overwrite beats row rule when showing horizontal..
    > .row {
      margin-left: -15px;
      margin-right: -15px;
    }

    .av-icon {
      background: $gold;
      text-align: center;
      color: #fff;
      width: 35px;
      height: 35px;
      display: inline-block;

      margin-right: 10px;

      i.fa {
        line-height: 35px;
      }
    }

    .av-label {
      display: inline-block;
      width: calc(100% - 50px);
      vertical-align: text-top;
    }
  }
}

.availability-options {
  .panel-body {
    font-size: 13px;
    padding: 10px;
  }
}

.fc-listWeek-view .fc-list-item .fc-list-item-title {
  color: white;
}