.map-container {

  width: 100%;
  height: 300px;

  //#map {
  //  height: 100%;
  //  margin: 0;
  //  padding: 0;
  //}

  background-color: #ddd;
  margin-top: 25px;
  margin-bottom: 25px;
}

#area-map {
  width: 1000px;
  height: 1000px;
}

#map:empty {
  //height: 0;
  //width: 0;
  //display: none;
}

.map-responsive-width {
  width: 100%;
  height: 500px;
}

.map {
  min-height:450px;
}

.thin-map {
  min-height: 250px;
  background-color: $light-grey;
}

#area-map-label {
  background: #fff;
  font-family: 'Roboto';
  padding: 8px;
  font-weight: 500;
  position: absolute;
  right: 25px;
  top: 10px;
  color: #000;
  z-index: 2;
  font-size: 11px;
  border-radius: 2px;
  border: $light-grey;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
}