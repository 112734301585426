.help-block {
  font-size: 14px;
  margin-left: 10px;
  margin-top: 8px;
  font-style: italic;
}

span.mandatory {
  color: $mandatory;
}

.control-label {
  &.mandatory::after {
    color: $mandatory;
    content: "*";
    position: relative;
    left: 2px;
    top: -2px;
  }
}

@media (max-width: $screen-xs-max) {
  .margin-bottom-xs {
    margin-bottom: 15px;
  }
}

.form-control.read-only {
  border: 0;
  box-shadow: none;
}

.form-inline.using-rows {
  > .row {
    background: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    &:nth-child(2n+1) {
      background: #f4f4f4;
    }
  }
}

.form-control.qty {
  width: 55px;
}

.first-row, .second-row {
  height: 34px;
  line-height: 34px;
}
.second-row {
  margin-top: 5px;
}

.force-input-block {
  display: block!important;
  width: 100%;
}

.additional-information {

  .info-label-span {
    margin-left: 5px;
    vertical-align: text-top;
  }

  .beat .row.padded {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .beat > .row.has-error {
    background: lighten($brand-danger, 30%);
  }

  .beat > .row.has-error:nth-child(odd) {
    background: lighten($brand-danger, 25%);
  }
}