.admin-form {

  .form-group {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 0;

    .form-control.read-only {
      background-color: transparent;
    }

    &:nth-child(2n+1) {
      background-color: #f8f8f8;
    }

    .control-label {
      padding-top: 5px;
    }

    .form-data {
      line-height: 34px;
      padding: 0 12px;
    }

    p.note {
      color: #666;
      font-size: 14px;
      margin: 0;
    }

    .thumb {
      display: inline-block;
      max-width: 24%;
      margin: 0px;
    }

    .form-control-static {
      padding-top: 5px;
    }
  }

  .bb-code-info {
    @media (min-width: $screen-md-min) {
      margin-left: -15px;
    }

    .row:nth-child(2n+1) {
      background-color: #f2f2f2;
    }
  }
}

//.btn-group {
//  .btn {
//    opacity: 0.5;
//
//    &.active,
//    &:hover {
//      opacity: 1;
//    }
//  }
//}

.dow-checkbox {
  label {
    border: 1px solid #ccc;
    text-align: center;
    width: 28px;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $input-color;
    background-color: $input-bg;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid $input-border;
    border-radius: $input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.
    @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
    @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);
    margin-bottom: 0;

    span {
      display: inline-block;
      width: 100%;
      padding: $padding-base-vertical $padding-base-vertical;
      font-size: $font-size-base;
      font-weight: normal;
      line-height: 1;
      color: $input-color;
      text-align: center;
      background-color: $input-group-addon-bg;
      border-bottom: 1px solid $input-group-addon-border-color;
    }

    input[type="checkbox"] {
      margin-top: 6px;
    }
  }
}



//blog tag styling

.admin-form {
  .blog-tag-input {

    .tt-menu {
      border: 1px solid #dadfd3;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
      color: #3d3d3d;
      padding: 3px 6px;
      background-color: #fff;

      .tt-suggestion {
        cursor: pointer;
      }
    }

    .tag-list {
      margin-top: 1em;
      list-style: none;
      padding: 0;

      li {
        display: inline-block;
        margin-right: 5px;
        margin-bottom: 5px;
        cursor: pointer;
      }
    }
  }
}