$chosen-background: $input-bg !default;
$chosen-border: 1px solid $input-border !default;
$chosen-border-color: $input-border-focus;
$chosen-border-radius: $input-border-radius !default;
$chosen-multi-border-radius: $chosen-border-radius !default;
$chosen-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075) !default;
$chosen-drop-border: $input-border !default;
$chosen-drop-box-shadow: 0 8px 8px rgba(0, 0, 0, .25) !default;
$chosen-drop-zindex: 1060 !default;
$chosen-focus-border: 1px solid $input-border-focus !default;
$chosen-focus-box-shadow: 0 1px 1px rgba(0, 0, 0, .075) inset, 0 0 8px $chosen-border-color !default;
$chosen-focus-transition: border linear .2s, box-shadow linear .2s !default;
$chosen-height: $input-height-base !default;
$chosen-multi-height: $input-height-base + 6px !default;
$chosen-sprite-path: 'chosen-sprite.png' !default;
$chosen-sprite-retina-path: 'chosen-sprite@2x.png' !default;
