.paragraphs-content {
    margin-bottom: 1em;
    
    .paragraph {
        img {
            max-width: 100%;
            width: 100%;
        }
    }

    .paragraph-single_image, .paragraph-double_image, .paragraph-triple_image {
        margin: 1em 0 0;
        text-align: center;

        .row > div {
            margin-bottom: 1em;
        }
    }

    .paragraph-embedded_video {
        max-width: 720px;
        margin: 1em auto;
        padding-bottom: 1em;
        clear: both;

        > div {
            text-align: center;
            position: relative;
            top: 1em;
            width: 100%;
            height: 0;
            padding-bottom: 51%;
            
            iframe {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }
    }

    .paragraph-text_with_image {
        .row {
            margin-top: 10px;
        }
        
        img{
            margin-bottom: 10px;
        }

        .wrapped {
            .wrap {
                float: right;
                margin-left: 1em;
                max-width: 360px;
            }
        }
    }

    .paragraph-horizontal_divider {
        hr {
            margin-left: 10%;
            margin-right: 10%;
            border-top: 1px solid #dddddd;
        }
    }

    .caption {
        display: block;
        padding: 0.5em;
        text-align: center;
        font-size: 0.9em;
        font-weight: bold;
    }
}

@media (max-width: 767px) {
    .paragraphs-content {
        .paragraph-single_image, .paragraph-double_image, .paragraph-triple_image {
            img {
                max-width: 550px;
                max-height: 400px;
                object-fit: contain;
            }
        }
        
        .paragraph-text_with_image {
            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
                max-width: 550px;
                max-height: 400px;
                object-fit: contain;
            }

            .wrapped {
                display: flex;
                flex-direction: column-reverse;
                
                .wrap {
                    float: none;
                    max-width: none;
                }
            }
        }
    }
}