.blog-suggestions {
    list-style: none;
    padding-left: 0;
}

.blog-posts-list {
    padding: 0;
    list-style: none;
    

    a {
        display: block;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
        border: 1px solid #dadfd3;
        padding: 15px;
        background-color: #fff;
        margin-bottom: 15px;
        color: #3d3d3d;
        transition: background-color 0.3s;
        text-decoration: none;

        &:hover {
            background-color: $light-grey;
        }

        .date {
            color: mix(#3d3d3d, $light-grey);
            float: right;
            font-size: 0.9em;
        }
    }
}

.blog-tag-links {
    margin-top: 0.6em;
    margin-bottom: 0.15em;

    a:first-child {
        margin-left: 1em;
    }
}

.blog-post-body {
    .img-blog-post {
        float: right;
        margin-left: 1em;
        margin-bottom: 1em;
        display: block;
    }
}

.blog-tag-search-group {
    .twitter-typeahead {
        width: 100%;

        .tt-menu {
            position: static !important;
            padding: 5px 0;
            margin-top: 5px;

            .tt-suggestion {
                display: inline-block;
                margin-left: 5px;
                margin-bottom: 5px;

                span {
                    cursor: pointer;
                    border: 1px solid #dadfd3;
                    display: inline-block;
                    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
                    color: #3d3d3d;
                    padding: 3px 6px;
                    background-color: #fff;
                    font-size: 0.85em;

                    &:hover {
                        background-color: $light-grey;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    .blog-post-body {
        .img-blog-post {
            float:none;
            margin-left: auto;
            margin-right: auto;
        }
    }
}