.catch-return-section {

  select.disabled, input.disabled {
    background: #ececec;
  }

  .returns {
    margin-bottom: 10px;
  }

  .catch-returns {
    //background: #f9f9f9;
    padding: 15px 0;
  }

  .fish-row {

    & > div {
      margin-bottom: 10px;
    }

    label {
      display: inline-block;
      width: 60%;
      font-size: 12px;
      padding-right: 10px;
      text-align: right;
      font-weight: 400;
    }

    input {
      display: inline-block;
      width: 40%;
    }
  }

  p {
    line-height: 24px;
    &.fish-note {
      font-size: 12px;
      font-style: italic;
      text-align: right;
      margin-bottom: 0;
    }
  }

  .dropzone {
    text-align: center;

    .dz-preview {
      width: 220px;
      margin: 0 5px 10px;
    }
  }

}

.outstanding-catch-returns {

  .return-to-submit {
    display: block;

    & + .return-to-submit {
      margin-top: 10px;
    }

    > div {
      display: block;
      padding: 5px 10px;
      background: #f8f8f8;
      cursor: pointer;

      &:hover {
        background: #f3f3f3;
      }

    }
  }
}

.pagination-p {
  line-height: 23px;
  padding: 7px 0;
  margin: 0 0 10px 0;
}

.pagination-container {
  line-height: 0;

  .pagination {
    margin: 0 0 10px 0;
  }

  @media (max-width: $screen-xs-max) {
    &.pull-right {
      float: none !important;
    }
  }
}

.card {
  border: 1px solid rgba(211, 211, 211, 0.80);
  padding: 10px 15px;
  border-radius: 2px;
  box-shadow: 1px 1px 3px 1px rgba(211, 211, 211, 0.80);
  background: #fcfcfc;

  &.inactive {
    background: #ececec;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  & + .card {
    margin-top: 20px;
  }

  .heading {
    margin-bottom: 10px;

    .text-muted {
      display: inline-block;
      margin-right: 10px;
      min-width: 40px;

      @media (max-width: $screen-xs-max) {
        min-width: 100px;
      }
    }

    .pull-right .text-muted {
      min-width: unset;
    }

    p {
      font-weight: 400;
      color: #555;
      margin-bottom: 0;

      &.date {
        font-style: italic;
        color: #777;
        font-size: 90%;
      }
    }
  }

  p {
    line-height: 26px;
    font-weight: 300;

    &.fishing-list {
      font-style: italic;
      color: #777;
      font-size: 90%;
      margin-bottom: 0;
    }

    &.thumbnails {
      margin-bottom: 0;
    }

  }

  .thumb {
    max-width: 150px;
    max-height: 150px;
    margin: 5px 5px 5px 0;
    display: inline-block;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  & *:last-child {
    margin-bottom: 0;
  }
}

.notice-image-list {
  margin-top: 10px;
  margin-left: 80px;

  > a {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 5px;

    img {
      border: 1px solid #eee;
      width: 120px;
      height: 120px;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .notice-image-list { 
    > a {
      img {
        width: 75px;
        height: 75px;
      }
    }
  }
}
