.section {
  padding-top: 30px;
  padding-bottom: 40px;

  h2 {
    font-size: 42px;
    font-weight: 200;
    line-height: 42px;
    text-shadow: 1px 1px 2px #999;
    margin-top: 0;
  }

  h3, h4 {
    font-weight: 200;
    text-shadow: 1px 1px 2px #aaa;
  }

  p {
    line-height: 24px;

    &.text-small {
      line-height: 20px;
    }
  }

  img {
    max-width: 100%;
  }

  h3:first-child {
    margin-top: 0;
  }

  &.alt {
    background: #686868;
    color: #f2f2f2;
  }

  &.feature {
    padding: 40px 0 45px;
    .container {
      background: #fafafa;
    }
    &.alt {
      .container {
        background: #686868;
      }
    }
  }

  &.beat {
    h3 {
      margin-top: 0;
    }

    #beat-carousel {
      margin-top: 36px;
    }

    .container {
      padding-bottom: 0;
    }
  }

  .alert {
    margin-top: 0;
  }

  @media (max-width: $screen-sm-max) {
    .container {
      padding-top: 20px;
      padding-bottom: 25px;
    }
    h2 {
      font-size: 32px;
    }
  }
}

.section.strut {
  padding-top: 70px;
  & > .container {
    min-height: calc(100vh - 307px - #{$navbar-height});
  }

}

.banner-header + .section.strut {
  & > .container {
    min-height: calc(100vh - #{$banner-col-height} - 307px - #{$navbar-height});
  }

  @media (max-height: $screen-short) {
    & > .container {
      min-height: calc(100vh - #{$banner-col-short-height} - 307px - #{$navbar-height});
    }
  }
}

.banner-header-sm + .section.strut {
  & > .container {
    min-height: calc(100vh - #{$banner-col-sm-height} - 307px - #{$navbar-height});
  }
}