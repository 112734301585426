

.banner {
  background-image: url('/images/header/underwater.jpg');
  background-position: center center;
  background-size: cover;
  width: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  position: relative;
  text-align: center;

  &.banner-owners {
    background: transparent;
    top: -$banner-col-height;

    @media (max-height: $screen-short) {
      top: -$banner-col-short-height;
      height: $banner-col-short-height;
    }
  }

 &.banner-header.banner-owners.affix {
    background-image: url("/images/carousel-small/ownersbanner1.jpg");

    @media (max-height: $screen-short) {
      height: $banner-col-short-height;
    }
  }

  &.no-image {
    background-image: none !important;
  }

  &:after {
    display: block;
    content: '';
    background-color: rgba(0,0,0,0.3);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }

  &.banner-header {
    height: $banner-col-height;

    @media (max-height: $screen-short) {
      height: $banner-col-short-height;
    }

    .carousel {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .carousel,
    .carousel-inner,
    .carousel-inner .item {
      height: 100%;
    }

    .carousel .carousel-inner .item {
      background-position: center center;
      background-size: cover;
    }
  }

  &.banner-header-sm {
    height: $banner-col-sm-height;
  }

  &.banner-area {
    height: 150px;
    margin-bottom: 30px;

    span {
      color: #fff;
      display: block;
      font-size: 14px;
      font-weight: 400;
      height: 20px;
      margin: auto;
      overflow: hidden;
      position: absolute;
      text-decoration: none;
      text-overflow: ellipsis;
      text-shadow: 1px 1px 2px #222;
      bottom: 6px;
      white-space: nowrap;
      width: 100%;
      padding: 0 5px;
      z-index: 2;
    }

    &.banner-sm {
      height: 75px;

      .banner-title {
        font-size: 24px;
      }

      &.has-sub-areas {
        .banner-title {
          top: 40%;
        }
      }
    }
  }

  &.banner-wild-stream {
    background-image: url('/images/header/wild-stream.jpg');
  }

   &.banner-whenwheresalmon {
    background-image: url('/images/header/when-where-salmon.jpg');
  }

  &.sea-trout-header {
    background-image: url('/images/header/sea-trout-catches.jpg');
  }

  &.salmon-header {
    background-image: url('/images/header/salmon-catches.jpg');
  }

  &.farlows-header {
    background-image: url('/images/farlows-trophy.jpg');
    background-size: contain;
    background-color: black;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  &.shop {
    background-image: url('/images/header/shop.jpg');
  }

  &.services {
    background-image: url('/images/header/instruction.jpg');
  }

  &.catch-release {
    background-image: url('/images/header/catch-release.jpg');
  }

  &.season-rods {
    background-image: url('/images/header/season-rods.jpg');
  }

  &.canoeing {
    background-image: url('/images/header/canoeing.jpg');
  }

  &.search-area {
    background-image: url('/images/header/search-area.jpg');
  }

  &.search-beat {
    background-image: url('/images/header/search-beat.jpg');
  }

  &.search-date {
    background-image: url('/images/header/search-date.jpg');
  }

  &.search-fishing {
    background-image: url('/images/header/search-fishing.jpg');
  }

  &.catch-returns {
    background-image: url('/images/header/catch-returns.jpg');
  }

  &.affix {
    position: fixed;
    z-index: 1045;
    top: $banner-affix-height;
    @media (max-height: $screen-short) {
      top: $banner-affix-short-height;
    }

    h1, .h1, .banner-title {
      top: 100%;
      transform: translateY(-106%);
    }

    .banner-admin {
      .basket-user {
        display: inline-block;
        margin: 0 10px;
        vertical-align: middle;
      }
    }
  }

  &.has-images:not(.affix) {
    h1, .h1 {
      transform: translateY(-120%);
    }
  }

  h1,
  .h1,
  .banner-title {
    display: inline-block;
    color: #fff;
    font-weight: 300;
    font-size: 38px;
    margin: 0;
    padding: 10px 20px;
    line-height: 47px;
    text-shadow: 1px 1px 2px #222;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
    z-index: 2;
    transition: top 1s, transform 1s;

    &.h1 {
      padding-left: 0;
    }

    small {
      color: #efefef;
      margin-left: 5px;
      font-weight: 300;

      a {
        color: #efefef;
        &:hover {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    h1, .banner-title {
      font-size: 24px;
      line-height: 48px;
    }

    &.affix h1,
    &.affix .banner-title {
      //transform: translateY(-100%);
    }

    .h1 {
      display: none;
    }
  }

  .banner-admin {
    position: absolute;
    bottom: 15px;
    right: 15px;
    text-align: right;
    z-index: 101;
    //width: 100%;
    transition: transform 0.3s;

    a.btn {
      .fa {
        margin-left: 10px;
        margin-right: 0;
      }
    }

    .basket-user {
      margin: 10px 0;
      padding: 6px 10px;
      background: #f5f5f5;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05) inset;
      border: 1px solid #e3e3e3;
      border-radius: 2px;

      p {
        margin: 0;
        font-size: 14px;
      }
    }

    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }
}

.banner-title {
  &:hover {
    text-decoration: none;
  }
}

//
//.navbar-obs-admin.collapse.in + .header-wrapper {
//  .banner-col {
//    .banner-admin {
//      top: (15px + $admin-navbar-height);
//    }
//  }
//}
//
//.navbar-obs-admin.collapse.in + .header-wrapper .banner-header {
//  &.affix {
//    top: -60px;
//    .banner-col {
//      .banner-admin {
//        top: 175px;
//      }
//    }
//  }
//}

//.navbar-obs-admin.collapse.in + .header-wrapper + .beat-filter .area .area-header.affix {
//  top: 35px;
//}

.banner-images {
  //height: 100px;
  z-index: 100;
  text-align: center;
  transition: height 1s;
  margin-top: -180px;
  position: relative;

  &.affix {
    z-index: 1046;

    //height: 0;
    .img-outer {
      margin-top: -500px;
      opacity: 0;
    }
    .row > div:nth-child(2) .img-outer {
      transition-delay: 0.1s;
    }

    .row > div:nth-child(3) .img-outer {
      transition-delay: 0.2s;
    }
  }
  > div {

    background-color: transparent!important;
  }

  .img-outer {
    display: inline-block;
    background-color: #fff;
    padding: 15px;
    opacity: 1;
    transition: margin-top 1s, opacity 1s;
    img {
      float: left;
      cursor: pointer;
    }
  }
}

.navbar-obs-admin.in + .banner:not(.affix) {
  > .banner-admin {
    //top: $admin-navbar-height + 10px;
  }
}

.banner.affix {
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    & > .banner-admin {
      //top: auto;
      transform: translateX(100%);
      //bottom: auto;

      &::after {
        content: "\f054";
        font-family: FontAwesome;
        display: block;
        position: absolute;
        background: #fff;
        border-radius: 2px;
        left: -28px;
        padding: 2px 5px 2px 6px;
        top: 3px;
        opacity: 1;
        transition: opacity .3s;
      }
    }

    &:hover > .banner-admin {
      transform: translateX(0);
      &::after {
        opacity: 0;
      }
    }
  }
}

.navbar-obs-admin.in + .banner.affix  {
  top: $banner-affix-height + $admin-navbar-height;
}

.area-beat-container {
  margin-top: -20px;
  .area-beat-outer {
    padding-top: 20px;
    .banner.banner-area {
      margin-bottom: 15px;
    }
  }
}

.hover {
  a.banner-title > div {
    filter: grayscale(1);
  }

  a.active.banner-title > div {
    filter: none;
  }
}

.sub-area-sidebar {
  margin-right: -30px;
  .banner.banner-area {
    margin-bottom: 15px;
    height: 60px;
    min-height: 60px;
    max-height: 217.5px;

    .banner-title {
      font-size: 22px;
    }
  }
}