/*
inspired from http://codepen.io/Rowno/pen/Afykb
*/
.carousel-fade {
  .carousel-inner {
    .item {
      opacity: 0;
      transition-property: opacity;
    }

    .active {
      opacity: 1;
    }

    .active.left,
    .active.right {
      left: 0;
      opacity: 0;
      z-index: 1;
    }

    .next.left,
    .prev.right {
      opacity: 1;
    }

  }

  .carousel-control {
    z-index: 2;
  }

}

/*
WHAT IS NEW IN 3.3: "Added transforms to improve carousel performance in modern browsers."
now override the 3.3 new styles for modern browsers & apply opacity
*/
@media all and (transform-3d), (-webkit-transform-3d) {

  .carousel-fade {
    .carousel-inner {
      > .item {
        &.next,
        &.active.right {
          opacity: 0;
          -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
        }

        &.prev,
        &.active.left {
          opacity: 0;
          -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
        }

        &.next.left,
        &.prev.right,
        &.active {
          opacity: 1;
          -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}

.carousel-fullscreen {
  width: 100%;
  height: 100%;

  .carousel-inner {
    width: 100%;
    height: 100%;

    .item {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: 20% center;
    }
  }
}

.carousel-small {
  height: $banner-col-height;

  @media (max-height: $screen-short) {
    height: $banner-col-short-height;
  }

  .carousel-inner {
    width: 100%;
    height: $banner-col-height;

    @media (max-height: $screen-short) {
      height: $banner-col-short-height;
    }

    .item {
      width: 100%;
      height: $banner-col-height;
      background-size: cover;
      background-position: 20% center;

      @media (max-height: $screen-short) {
        height: $banner-col-short-height;
      }
    }
  }
}