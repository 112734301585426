//@import "app/variables";
//@import "bootstrap/variables";

.admin {

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .container {
      width: 90vw;
      max-width: 970px;
      min-width: 750px;
    }
  }

  .table {
    font-size: 13px;

    tr.data-tr {
      font-style: italic;

      @media (max-width: $screen-xs-max) {
        display: none;
      }
    }

    th {
      a {
        text-decoration: none;
      }
    }

    .table-icon {
      border: 0;
      padding: 0;

      & + .table-icon {
        margin-left: 7px;
      }

      &.delete {
        color: $delete;
      }

      &.edit {
        i {
          position: relative;
          top: 1px;
        }
      }
    }

    & > thead,
    & > tbody,
    & > tfoot {
      & > tr.not-active {
        > td,
        > th {
          background-color: #f2f2f2;
          color: #777777;
        }

        &:hover {
          > th, > td {
          }
        }
      }

      & > tr.trashed {
        > td,
        > th {
          opacity: 0.55;
          background-color: #f2f2f2;
          color: #777777;
        }
        &:hover {
          > th, > td {
            opacity: 1;
          }
        }
      }

      td.money,
      th.money {
        text-align: right;
        letter-spacing: 1px;
      }
    }
  }

  .pagination-container {
    font-size: 13px;

    @media (max-width: $screen-xs-max) {
      font-size: 11px;

      &.pull-right {
        float: none !important;
      }
    }

    .pagination {
      margin: 5px auto;

      > li > a,
      > li > span {
        padding-top: 2px;
        padding-bottom: 2px;

        @media (max-width: $screen-xs-max) {
          padding: 8px 4px;
        }
      }
    }
  }

  .asc::after {
    content: "\f077";
    font-family: "fontawesome";
  }

  .desc::after {
    content: "\f078";
    font-family: "fontawesome";
  }

  .admin-search-form {
    background-color: #fafafa;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
    margin-bottom: 10px;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;

    .form-group {
      margin-bottom: 5px;

      .control-label {
        padding-right: 0;
      }
    }

    .form-group-sm {
      label {
        font-size: 12px;
        padding-top: 4px;
      }
    }
  }

  .section {
    h1 {
      font-size: 42px;
      font-weight: 200;
      line-height: 42px;
      text-shadow: 1px 1px 2px #999;
      margin-top: 0;
    }

    h2 {
      font-weight: 200;
      text-shadow: 1px 1px 2px #aaa;
      font-size: 28px;
    }
  }

  h1, h2 {
    small {
      font-weight: 200;
      color: #333;
      font-style: italic;
    }

    a.btn {
      margin-left: 10px;
      .fa {
        margin-right: 10px;
      }
    }
  }

  div.actions {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 0px;
    background-color: #fafafa;
    border: 1px solid #f1f1f1;
    box-shadow: 2px 2px 2px #f1f1f1;

    .btn-sm,
    .btn-group-sm > .btn {
      padding: 3px 8px;
    }

    span {
      margin-right: 5px;

      &.caret {
        margin: 0;
      }
    }

    a + span {
      margin-left: 5px;
      border: 0;
    }

    div.options {
      font-size: 13px;
      display: inline-block;
      margin: 0;
      padding: 5px 8px;

      &.customer {
        background-color: #64b5f6;
      }
      &.beat-admin {
        background-color: #e57373;
      }
    }

    .btn-group {
      .dropdown-menu {
        padding-top: 3px;
        padding-bottom: 4px;
        font-size: 12px;

        & > li > a {
          padding: 3px 10px;
        }
      }
    }
  }

  .wuf-ex {
    float: right;
    font-size: 60%;
    font-weight: 300;

    i.fa {
      margin-left: 5px;
    }
  }

  h3 .wuf-ex {
    line-height: 26px;
  }

  .row.em {
    .data-label {
      font-weight: 600;
    }

    .data {
      font-weight: 300;
    }
  }

  .data-label {
    text-align: right;
    line-height: 1.8em;

    @media (max-width: $screen-xs-max) {
      text-align: left;
      line-height: 1.5em;
    }
  }

  .data {
    font-weight: 200;
    line-height: 1.8em;
  }

  .order-beats {
    margin-top: 10px;

    .beat {
      margin-bottom: 10px;

      label {
        display: block;
        margin: 0;

        &.retired {
          opacity: 0.6;

          &:hover {
            opacity: 1;
          }
        }

        input {
          display: none;
        }

        & > span {
          display: block;
          padding: 5px 10px;
          background: #f8f8f8;
          cursor: pointer;

          &:hover {
            background: #f3f3f3;
          }

          span.area {
            font-size: 12px;
            font-weight: 200;
          }
        }

        input[type="radio"]:checked + span {
          box-shadow: inset 0 0 10px 4px #d6d6d6;
        }
      }
    }

    .beat-icon {
      margin: 0 2px;
      cursor: default;
    }

    .row {
      margin-left: 5px;
      margin-right: 5px;
      .col {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  .order-beat {

    .beat-description {
      font-size: 14px;
      border: 1px solid #ccc;
      border-radius: 2px;
      padding: 5px;
      background: #f8f8f8;
    }

    .order-beat-labels span {
      display: inline-block;
      margin: 0 5px;
      i.fa {
        margin-right: 5px;
      }
    }
  }

  .row + .calendar {
    margin-top: 10px;
  }

  .calendar {

    .slots-left {
      padding: 4px;
    }

    .fc-basic-view {

      .fc-day-number {
        font-size: 26px;
        padding: 0 10px;
        font-weight: 200;
      }

      .fc-widget-content {
        .fc-bg {
          .fc-today {
            box-shadow: inset 0 0 3px 3px rgb(92, 184, 92);
            background: none;
          }

          .fc-day:hover {
            box-shadow: inset 0 0 3px 3px rgb(46, 109, 164);
          }

          .fc-other-month {
            background: #efefef;
          }

          .fc-past {
            opacity: 0.5;

            &:hover {
              opacity: 1;
            }
          }

          .unavailable {
            background: rgba(199, 43, 20, 0.63);
          }
        }
      }

      .av-grid-container {
        padding: 3px;

        .av-grid {
          display: table;
          width: 100%;
          empty-cells: show;
          height: 24px;

          & > div {
            display: table-cell;
            border: 1px solid #d6d6d6;
          }
        }
      }
    }
  }

  .btn-sm-height {
    line-height: 30px;
  }

  .popover-thumb {
    .popover-content {
      padding: 4px;
      img {
        border-radius: 3px;
      }
    }
  }

  .table td.td-thumb {
    padding: 1px;
    width: 1%;
    font-size: 0;

    &.td-attachments {
      width: auto;
    }

    .thumb {
      img {
        max-height: 31px;
        margin: 2px;
      }
    }
  }

  .thumb {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      color: #23527c;
    }
  }

  .hover-thumb {
    display: inline-block;
    height: 100%;
    padding: 0 2.5px;

    img {
      display: none;
      border: 1px solid #ccc;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    }

    &:hover {
      img {
        display: block;
        position: absolute;
        // max-width: 50%;
        // max-height: 50%;
        z-index: 1337;
      }
    }
  }

  .upload-row + .upload-row {
    //margin-top: 10px;
  }
  .upload-row {
    padding-top: 5px;
    padding-bottom: 5px;

    &:nth-child(odd) {
      background-color: #f7f7f7;
    }

    .btn-group {
      display: block;

      &::after {
        content: " ";
        display: table;
        clear: both;
      }
    }
  }

  .upload-submit {
    padding-top: 10px;
    padding-right: 0px;
  }

  .panel-beat {
    .panel-heading {
      small {
        margin-left: 10px;
      }
    }

    .panel-body {
      padding-bottom: 10px;
      font-size: 0;

      a.btn {
        margin-bottom: 5px;
        margin-right: 5px;

        .fa {
          margin-right: 5px;
        }
      }
    }
  }

  .table-responsive {
    margin-bottom: 10px;
  }

  //.fa-fixed {
  //  .fa {
  //    width: 26px;
  //  }
  //}

  .filter-label {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
    border: 1px solid #dadfd3;
    padding: 7px 35px 7px 10px;
    background-color: #fff;
    margin-bottom: 3px;
    transition: background-color 0.3s;
    position: relative;
    cursor: pointer;

    &.busy {
      cursor: not-allowed;

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(#fff, .8);
        top: 0;
        left: 0;
        position: absolute;
      }
      //> span::after {
      //  content: "\f021";
      //  font-family: FontAwesome;
      //  display: block;
      //  position: absolute;
      //  animation: 2s linear 0s normal none infinite running fa-spin;
      //  top: 8px;
      //  left: 43%;
      //}
    }
    .fa {
      margin-right: 7px;
    }

    input {
      display:none;

      &:checked + span:after {
        font-family: FontAwesome;
        content: "\f058";
        color: $brand-success;
        display: block;
        position: absolute;
        top: 4px;
        right: 9px;
        font-size: 19px;
      }
    }

    &:hover {
      background-color: $light-grey;
    }
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .col-sm-4 {
      .filter-label {
        width: 100%;
      }
    }
  }

  .text-wild-stream {
    color: #ff5722;
  }

  .text-river {
    color: #3f51b5;
  }

  .text-stillwater {
    color: #42a5f5;
  }

  .resize-multi-select {
    resize: auto;
    min-height: 200px;
  }

} //.admin
/* Editor */

.editor-panel {

  table.map-layers > tbody {
    > .active td {
      background-color: lighten($gold, 40%);
    }
  }
}

.editor-btn {
  background-color: $btn-primary-bg;
  color: $btn-primary-color;
  text-align: center;
  font-size: 40px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: $btn-border-radius-base;
  border: 1px solid $btn-primary-border;
  width: 100%;

  cursor: pointer;

  &:hover {
    background-color: darken($btn-primary-bg, 10%);
  }

  &.active {
    background-color: #e0b709;
    color: #fff;
    border: 1px solid $gold;
  }
}

.fa.fa-external-link {
  margin-left: 5px;
}

#beatJob > div.row:nth-child(odd) > .col-xs-12 {
  background: #fbfbfb;
}

.send-email {
  .fa {
    margin-right: 5px;
  }
}

.navbar-obs-admin {
  .dropdown-menu {
    > .container > .row > div + div {
      border-left: 1px solid rgba(#fff, 0.3);
    }

    .heading {
      border-bottom: 1px solid rgba(#fff, 0.3);
    }

    @media (max-width: $screen-xs-max) {
      .heading {
        margin-top: 5px;
        color: #4d5359;
        border-bottom: 1px solid rgba(#4d5359, 0.3);
      }

      > .container > .row > div + div {
        border-left: 0;
      }
    }
  }

  //@media (max-width: $screen-xs-max) {
  //  .navbar-nav > li > a:hover::before,
  //  .navbar-nav > li > a:focus::before {
  //    color: #4d5359;
  //    text-shadow: none;
  //  }
  //}
}


#panel-affix {
  &.affix {
    top: 139px;
    width: 375px;

    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      width: 308px;
    }
    //
    //@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    //  max-width: 282px;
    //  min-width: 235px;
    //  width: 100%;
    //}

    @media (max-width: $screen-xs-max) {
      width: 100%;
    }
  }
}

th.fishing-type,
td.fishing-type {
  line-height: 1.2;
  padding-bottom: 6px;
  position: relative;

  span.stats-label {
    //display: block;
    //position: absolute;
    //transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    //position: absolute;
    //top: 50%;
    //left: 50%;
  }

  span.bar {
    bottom: 0;
    display: block;
    height: 3px;
    left: -1px;
    position: absolute;
    width: 100%;
  }
}

.dl-horizontal {
  hr {
    margin: 5px 0;
  }
}

.search-elements {
  margin-top: 10px;

  .element {
    margin-bottom: 10px;

    label {
      display: block;
      margin: 0;
      background: #f8f8f8;
      cursor: pointer;
      overflow: hidden;

      &.retired {
        opacity: 0.6;
        background: lighten($brand-danger, 30%);

        &:hover {
          opacity: 1;
          background: lighten($brand-danger, 20%);
        }
      }

      &:hover {
        background: #f3f3f3;
      }

      &.with-img {
        span {
        }
      }

      span {
        padding: 5px 10px;
        display: inline-block;
        width: 100%;
        min-height: 56px;
        span {
          padding: 0;
          width: auto;
          min-height: 0;
        }

        @media (max-width: $screen-xs-max) {
          border: 1px solid #ccc;
        }
      }

      &.beat-slug span {
        padding: 0;
        min-height: 0;
        border: 0;
      }

      img {
        display: inline-block;
        width: 100%;
      }

      input {
        display: none;
      }

      input[type="radio"]:checked + img {
        filter: grayscale(80%);
      }

      input[type="radio"]:checked + img + span {
        //box-shadow: inset 0 0 10px 4px lighten($gold, 20%);
        background-color: lighten($gold, 30%);
        //color: #efefef;
      }

      input[type="checkbox"]:checked + span,
      input[type="radio"]:checked + span {
        //box-shadow: inset 0 0 10px 4px lighten($gold, 20%);
        background-color: lighten($gold, 30%);
        //color: #efefef;
      }

      &.beat-slug {
        input[type="checkbox"]:checked + span,
        input[type="radio"]:checked + span {
          background: none;
        }
      }
    }
  }

  .beat-icon {
    margin: 0 2px;
    cursor: default;
  }

  .row {
    margin-left: -5px;
    margin-right: -5px;
    .col {
      padding-left: 5px;
      padding-right: 5px;
      @media (max-width: $screen-xs-max) {
        &.fold-xs {
          display: inline-block;
          width: auto;
        }
      }
    }
  }
}

.av-favourite {
  .fa::before {
    content: "\f006";
  }

  &.favourited {
    .fa::before {
      content: "\f005";
    }
  }
}
