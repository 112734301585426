.basket-menu {
  width: 400px;
  max-width: 50vw;
}

.summary-btns {
  margin: 0 10px 5px;
}

th.actions,
td.actions {
  width: 1%;
}


.basket {

  .table td, .table th {
    vertical-align: middle;
  }

  tr.no-border {
    td, th {
      border: 0;
    }
  }

  .input-group.slots {
    max-width: 116px;

    .input-group-addon {
      border-left: 0;
      padding-left: 10px;
      padding-right: 10px;
    }

  }

  input.qty {
    max-width: 60px;
    display: inline-block;

  }

  input.qty + label {
    font-weight: normal;
    margin-left: 5px;
  }

  .input-group.price {
    max-width: 150px;
  }

  td.actions {
    text-align: center;
  }

  .basket-checkboxes {
    label {
      border: 1px solid #ccc;
      text-align: center;
      width: 45px;
      font-size: $font-size-base;
      line-height: $line-height-base;
      color: $input-color;
      background-color: $input-bg;
      background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
      border: 1px solid $input-border;
      border-radius: $input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.
      @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
      @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);
      margin-bottom: 0;

      span {
        display: block;
        width: 100%;
        padding: $padding-base-vertical $padding-base-vertical;
        font-size: $font-size-base;
        font-weight: normal;
        line-height: 1;
        color: $input-color;
        text-align: center;
        background-color: $input-group-addon-bg;
        border-bottom: 1px solid $input-group-addon-border-color;
      }

      input[type="checkbox"] {
        margin-top: 6px;
      }
    }
  }

  &.summary {
    min-width: 320px;

    //th {
    //  text-align: right;
    //}

    .empty-basket {
      margin: 10px;
      text-align: center;
    }

    .form-group {
      max-height: 70vh;
      overflow: auto;
    }
  }

  tr.type-booking {

    td:first-child {
      white-space: normal;
    }

    span.date-info {
      margin-right:  8px;;
    }

    span.ticket-info {
      display: inline-block;
    }
  }

  .col-quantity {
    white-space: normal !important;
  }
}

@media (max-width: $screen-xs-max) {
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > tfoot > tr > td {
    &.td-ticket {
      min-width: 50px;
      white-space: normal;
    }

    &.th-quantity {
      width: 1%;
    }
  }

  .basket {
    .col-quantity {
      max-width: 50px;
    }
  }

  .basket-buttons {
    .btn {
      font-size: 14px;
      padding: 7px 10px;
    }
  }
}