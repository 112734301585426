.beat-filter {
  .area {
    padding: 0 px;
    margin: 5px 0 25px;

    &.wild-stream {
      background: #e2e2e2 none repeat scroll 0 0;
      border: 1px solid #e2e2e2;
      border-radius: 3px;
      padding: 40px 10px 10px;
      position: relative;

      &::after {
        content: "Wild Stream Beats";
        font-size: 20px;
        height: 50px;
        left: 0;
        position: absolute;
        text-align: center;
        top: 5px;
        width: 100%;
      }
    }
  }

  .side-menu {
    .side-menu-section {
      span.fishing-type-key {
        margin-right: 4px;
      }

      .checkbox {
        margin: 0;
        padding: 1px 0;
      }

      & + .filter-label {
        margin-top: 10px;
      }
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      padding-left: 0;
    }
  }
}

.collapse-heading {
  a {
    display: block;
    text-decoration: none;
    color: #666;

    .collapse-icon {
      margin-top: 3px;
    }

    .collapse-icon::before {
      content: "\f062";
    }

    &.collapsed {
      .collapse-icon::before {
        content: "\f063";
      }
    }
  }
}

.area-header-wrapper {
  height: 150px;
  margin-bottom: 15px;
}

.area-header {
  height: 150px;
  background-image: url('/images/river-sample.jpg');
  background-position: center center;
  background-size: cover;
  position: relative;

  // :ess chaotic
  .banner-title {
    transition: none;
    text-decoration: none;

    &:hover h2 {
      text-shadow: 1px 1px 5px #aaa;
    }
  }

  h2 {
    margin: 0;
  }

  &.area-header-banner {
    visibility: hidden;
    height: 0;

    &.affix {
      visibility: visible;
      position: fixed;
      height: $banner-col-height;
      top: $banner-collapsed-height + $navbar-height - $banner-col-height;
      left: 0;
      z-index: 1046;
      width: 100%;
      box-shadow: none;

      h2 {
        font-size: 38px;
        font-weight: 300;
        line-height: 47px;

        @media (max-width: $screen-xs-max) {
          font-size: 24px;
        }
      }
    }
  }
}

.navbar-obs-admin.collapse.in + .banner + .content {
  .area-header.area-header-banner.affix {
    top: $banner-affix-height + $admin-navbar-height;
  }
}

.beats {

  .av-date-title {
    font-weight: 600;
    line-height: 40px;
  }

  //padding: 0 10px;
  .row {
    margin-left: -5px;
    margin-right: -5px;

    .filter-col {
      padding-right: 5px;
      padding-left: 5px;
    }

    .filter-col + .filter-col {
      //padding-left: 5px;
    }
    .filter-col:nth-child(4n) {
      //padding-right: 15px;
    }

    & .clearfix {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .beat-slug {
      margin-bottom: 0;
    }

    .beat {
      background-color: #f8f8f8;
      padding: 10px;
      min-height: 110px;
      border: 1px solid #f8f8f8;

      &:hover {
        background-color: #efefef;
        cursor: pointer;
        box-shadow: 0 0 5px #e4e4e4;
        border: 1px solid #e4e4e4;
      }

      a:hover {
        text-decoration: none;
        outline: 0 none !important;
      }

      .title {
        border-bottom: 1px solid #e4e4e4;
        min-height: 40px;
        padding-bottom: 2px;
        margin-bottom: 2px;
      }

      h4, .h4 {
        margin: 0;
      }

      p {
        margin: 0;
        font-size: 14px;
        line-height: 24px;
      }
    }

  }

  @media (max-width: $screen-sm-max) {
    .row {
      padding-top: 10px;
    }

    .row:nth-child(even) {
      background: #f4f4f4;
    }
  }

  @media (max-width: $screen-xs-max) {
    padding: 0;

    .row {
      margin-left: -15px;
      margin-right: -15px;

      .filter-col {
        padding: 0 15px;
        margin-bottom: 10px;

        .beat {
          min-height: 94px;

          .title {
            min-height: 20px;
          }
        }
      }
    }
  }
}

.panel-group {
  .panel-heading {
    a {
      display: block;
      color: inherit;
      outline: none;

      &:hover, &:focus {
        text-decoration: none;
      }

      &:before {
        font-family: FontAwesome;
        content: "\f0d8";
        display: inline-block;
        float: right;
        vertical-align: middle;
      }

      &.collapsed:before {
        content: "\f0d7";
      }
    }
  }
}

.slug-height {
  line-height: 55px;
  .fa {
    font-size: 1.5em;
    margin-left: 10px;
  }

  a + span {
    margin-left: 10px;
  }
}