#assign-availability {
  .input-av {
    height: 32px;
    margin: auto;
    text-align: center;
    width: 44px;
    padding: 6px 6px;
  }

  th.slot, td.slot {
    text-align: center;
    width: 48px;
  }

  .table > tbody > tr > td.slot-label {
    vertical-align: middle;
    line-height: 32px;
  }
  .table {
    .row-0 {
      td.slot:nth-child(2n),
      &.title-row td.slot:nth-child(2n+1) {
        background: #f9f9f9 none repeat scroll 0 0;
      }

      &.title-row td.slot:nth-child(2n) {
        background: transparent none repeat scroll 0 0;
      }
    }

    .row-1 {
      td {
        background: #f3f3f3 none repeat scroll 0 0;
      }

      td.slot:nth-child(2n),
      &.title-row td.slot:nth-child(2n+1) {
        background: #e9e9e9 none repeat scroll 0 0;
      }

      &.title-row td.slot:nth-child(2n) {
        background: #f3f3f3 none repeat scroll 0 0;
      }
    }
  }

  .table .btn-xs {
    margin: 4px 2px;
    visibility: hidden;
  }

  .table td:hover .btn-xs {
    visibility: visible;
  }

  .table td.col-btn {
    text-align: center;

    .btn-xs {
      margin: 2px;
    }
  }

  .table td.col-add-ticket-type {
    line-height: 30px;
    text-align: right;
  }

  .table tr.title-row td {
    border-top-width: 2px;
  }

  .table tr.title-row.tr-stealth td {
    border-top-width: 1px;
  }

  .table tr.tr-stealth {
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }

  .table > tbody > tr > td.ticket-label {
    vertical-align: middle;
  }
}

.add-fishing-type {
  background: #f9f9f9 none repeat scroll 0 0;
  margin-top: 20px;
  padding: 20px 0 10px;
}