.navbar-obs {
  margin: 0;
  padding: 0;
  border-radius: 0;
  border-bottom: 3px solid $gold;
  background-color: $dark-grey;
  box-shadow: 0 0 15px rgba(0,0,0,0.30);
  text-transform: uppercase;
  z-index: 1049;

  .navbar-brand {
    height: $navbar-height - 11px;
    padding: 0 30px 0 30px;
    margin-top: 4px;

    img {
      max-height: 100%;
    }

    @media (max-width: $screen-md-max) {
      padding: 0 10px;
    }
  }

  .navbar-nav {
    margin-top: 24px;

    @media (min-width: $screen-sm-min) {
      &.navbar-right {
        .dropdown-menu {
          text-align: right;
        }
      }
    }

    > .open > a:hover {
      color: #fff;
    }

    > li {

      padding: 0 6px;

      > a {
        padding: 6px 6px 3px;
        color: #fff;
        font-size: 15px;
        font-weight: 600;
        outline: 0;
        background-size: 100% 200%;
        background-image: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.6) 50%);
        -webkit-transition: color 0.3s;
        -moz-transition: color 0.3s;
        transition: color 0.3s;
        //transition: font-size 0.3s, padding 0.3s;

        &::before {
          position: absolute;
          top: 80%;
          left: 50%;
          color: transparent;
          content: '•';
          text-shadow: 0 0 transparent;
          font-size: 1.2em;
          -webkit-transition: text-shadow 0.3s, color 0.3s;
          -moz-transition: text-shadow 0.3s, color 0.3s;
          transition: text-shadow 0.3s, color 0.3s;
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          transform: translateX(-50%);
          pointer-events: none;
        }
        &:hover::before,
        &:focus::before {
          color: lighten($gold, 20%);
          text-shadow: 10px 0 lighten($gold, 20%), -10px 0 lighten($gold, 20%);
        }

        &:hover,
        &:focus {
          color: $gold;
        }

        @media (max-width: $screen-md-max) {
          font-size: 13px;
          padding: 6px 6px;
        }
      }

      &.open > a:hover {
        color: $gold;
      }

      &.active > a {
        color: $gold;
        &::before {
          color: lighten($gold, 20%);
          text-shadow: 10px 0 lighten($gold, 20%), -10px 0 lighten($gold, 20%);
        }
      }

      &.dropdown {
        position: static;
      }
    }

    > li.dropdown > .dropdown-menu {
      position: absolute;
      border: 0;
      margin-top: 3px;
      right: 0;
      left: 0;
      border-radius: 0;
      background-color: rgba(32, 35, 37, 0.92);
      color: #fff;
      z-index: 1050;
      text-align: left;
      min-height: $banner-collapsed-height;
      padding: 15px 0;

      @media (min-width: $screen-lg-min) {
        .container {
          margin-left: 200px;
        }
      }

      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        .container {
          margin-left: 158px;
        }
      }

      ul {
        list-style: none;
        padding-left: 0;
        li {
          padding: 3px 0;
          > a, > span {
            padding: 5px 5px;
            color: #fff;
            font-weight: 600;
            font-size: 15px;
            display: inline-block;
            width: 100%;
          }
        }
      }

      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        .container {
          margin-left: 155px;
          width: calc(100% - 155px);

          ul li {
            > a, > span {
              font-size: 13px;
            }
          }
        }
      }

      a {
        color: #fff;
      }

      span.heading {
        display: block;
        padding: 8px 20px;
      }
    }

    //@media (max-width: $screen-xs-max) {
    //margin-top: 0;
    //margin-bottom: 0;
    //
    //> li {
    //  &.active {
    //    > a:before {
    //      content: none;
    //    }
    //  }
    //
    //  > a.dropdown-toggle{
    //    display:none;
    //  }
    //
    //  > .dropdown-menu {
    //    float: none;
    //    position: static;
    //    width: auto;
    //    display: block;
    //    box-shadow: none;
    //    border: 0;
    //    margin: 0;
    //    padding: 0;
    //
    //    > li > a {
    //      padding: 6px;
    //
    //      background-size: 100% 200%;
    //      background-image: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.6) 50%);
    //
    //      &:hover {
    //        background-color: transparent;
    //        background-position: 0 100%;
    //        color: #fff;
    //      }
    //    }
    //  }
    //}
    //}

    &.navbar-right {
      > li.dropdown > .dropdown-menu .container {
        text-align: right;
        margin-left: auto;
        margin-right: 0;

        ul li.heading {
          margin-right: 10px;
        }

        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
          width: 750px;
        }
      }
    }

  }

  &.navbar-obs-admin {
    top: $navbar-height;
    min-height: $admin-navbar-height;
    background-color: rgba(255,255,255, 0.9);
    border-bottom: 0;
    z-index: 1048;

    @media (max-height: $screen-short) {
      &.in + .banner.affix {
        top: $banner-affix-short-height + $admin-navbar-height;
      }
    }

    .navbar-nav {
      margin-top: floor(($admin-navbar-height - $font-size-base) /2) - 8px;
      > li {
        padding: 0 4px;

        &.active > a {
          color: $gold;
          background-color: transparent;
        }
        &.dropdown > .dropdown-menu {
          margin-top: 0;
        }
        > a {
          color: $dark-grey;
          &:before {
            display: none;
          }
          &:hover, &:focus {
            color: $gold;
          }
        }
      }
    }

    .navbar-brand {
      margin-top: 0;
      color: $text-muted;
      height: $admin-navbar-height;
      line-height: $admin-navbar-height;
    }

    .navbar-form {
      margin-top: 3px;
      margin-bottom: 0px;
      height: 32px;

      .form-control {
        font-size: 12px;
        height: 30px;
      }

      span.target-span {
        font-size: 12px;
        line-height: 30px;
        vertical-align: middle;
        margin-right: 5px;
      }

      .twitter-typeahead {
        position: inherit !important;
        vertical-align: middle !important;

        input {
          left: auto !important;
          right: 0;
          width: 0px;
          transition: width 1s ease;

          &:focus {
            width: 150px;
          }

          &.tt-hint {
            opacity: 0 !important;
          }

          &#quick-search {
            background: #ffffff;
          }
        }


        .tt-dataset {
          //background-color: #fff;
          padding: 0 15px 15px;
          .tt-suggestion {
            text-transform: none;
            padding: 0px;
            cursor: pointer;
            span:hover {
              text-decoration: underline;
              color: #fff;
            }
          }

          p {
            margin: 0;
          }
        }

        .tt-menu {
          width: 100%;
          right: 0;
          top: 36px;
          background-color: rgba(32, 35, 37, 0.92);
          z-index: 1050;
          text-align: left;
          padding: 15px 0 0;
          color: #ffffff;
          max-height: 30vh;
          overflow: auto;
        }
      }
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      .navbar-nav {

        > li > a {
          padding-left: 2px;
          padding-right: 2px;
        }

        > li.dropdown > .dropdown-menu {
          .container {
            margin-left: 0px;
            width: 100%;

            ul li {
              > a, > span {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }

  .caret {
    display: none;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .navbar-nav > li {
      padding: 0 6px;
    }
  }

  @media (max-width: $screen-xs-max) {
    .navbar-toggle {
      margin-top: 15px;
      margin-bottom: 15px;
      color: #fff;
      background: lighten($dark-grey, 5%);
      padding: 9px 15px;
    }

    .navbar-nav {
      margin-top: 10px;
      margin-bottom: 0;
    }

    .navbar-nav > li > a {
      &::before {
        content: "";
      }
    }

    .navbar-nav > li.dropdown > a.dropdown-toggle {
      cursor: default;

      &:hover,
      &:focus {
        color: #4d5359;
      }
    }

    .navbar-nav > li.dropdown > .dropdown-menu {
      display: block;
      position: relative;
      width: 100%;
      padding: 0;
      background: transparent;
      box-shadow: none;
      margin: 0;
      float: none;

      .container {
        margin: 0;
        padding: 0;
        .row {
          margin: 0;
          padding: 0;
          > div {
            margin: 0;
            padding: 0;
          }
        }
      }

      ul {
        padding: 0;

        li {
          display: block;
          position: relative;
          padding: 0;

          > a {
            font-size: 13px;
            padding: 6px;
            transition: color 0.3s ease 0s;
            text-decoration: none;
            display: block;
            line-height: 20px;

            &:hover,
            &:focus {
              //color: #4d5359;
              color: $gold;
            }
          }
        }
      }
    }

    .navbar-nav.navbar-right {
      margin-top: 0;
      margin-bottom: 10px;
      > li.dropdown > .dropdown-menu .container {
        text-align: left;
      }
    }

    &.navbar-obs-admin {
      background: #fff;

      .navbar-toggle {
        background: darken(#fff, 5%);
        color: lighten($dark-grey, 5%);
        padding: 5px 15px;
        border-radius: 0;
        margin-top: 0;
        margin-bottom: 0;
      }

      .navbar-nav > li.dropdown > .dropdown-menu {
        float: none;
        min-height: 0;
        ul {
          padding-left: 10px;
          li > a {
            color: lighten($dark-grey, 15%);

            &:hover,
            &:focus {
              color: $gold;
            }
          }
        }
      }

      .navbar-form {
        height: auto;
        margin-bottom: 5px;

        .twitter-typeahead input {
          min-width: 150px;
          width: 100%;
          &:focus {
            width: 100%;
          }
        }
      }
    }

    .navbar-collapse {
      max-height: $navbar-collapse-max-height;
      max-height: calc(100vh - 80px);
    }

    .tt-hint {
      display: none;
    }

    ul > li {
      margin-bottom: 0;
    }
  }
}



//
//.navbar-obs-admin {
//  top: $navbar-height;
//  min-height: 0;
//  z-index: 1048;
//
//  background-color: #f4f4f4;
//
//  .navbar-nav {
//    margin-top: 0;
//
//    li.active > a::before {
//      content: "";
//    }
//  }
//
//  .navbar-brand {
//    height: auto;
//    margin-top: 6px;
//    font-size: 16px;
//    @media (max-width: $screen-xs-max) {
//      display: none;
//    }
//  }
//
//  .dropdown-menu {
//    background-color: #f4f4f4;
//
//    > li > a:hover,
//    > li > a:focus {
//      background-color: transparent;
//    }
//  }
//
//  &.collapse.in + .section {
//    padding-top: $admin-navbar-height;
//  }
//
//  &.collapse.in + .section.strut {
//    > .container {
//      min-height: calc(100vh - 307px - #{$admin-navbar-height} - #{$navbar-height});
//    }
//  }
//
//  @media (max-width: $screen-xs-max) {
//    .navbar-toggle {
//      margin-top: 5px;
//      margin-bottom: 5px;
//    }
//  }
//}
//
//.admin-menu.dropdown-toggle {
//
//
//}
.admin-menu.dropdown-toggle {
  cursor: pointer;

  &:hover {
    text-decoration: underline;

    span {
      text-decoration: underline;
    }
  }

  .menu-show {
    display: none;
  }
  .menu-hide {
    display: inline-block;
  }

  &.collapsed {
    .menu-show {
      display: inline-block;
    }
    .menu-hide {
      display: none;
    }
  }
}