body.integration {
    padding-top: 0;

    #toast-container.toast-top-right {
        top: 10px;
    }

    .integration-button-stack {
        a {
            margin-bottom: 0.5em;
        }
    }

    .content{
        padding-top: 0;
        padding-bottom: 10px;
    }

    //beat - calendar page
    .modal-wrapper {
        .modal-container {
            margin-left: 15px;
            margin-right: 15px;
        }
        
        .modal-header {
            h3 {
                margin-bottom: 0;
            }
        }
        .vue-modal-body {
            margin-top: 10px;
            margin-bottom: 10px;
            .form-inline {
                .form-control, .form-control-strut {
                    margin-top: 2px;
                    margin-bottom: 2px;
                }
            }
        }
    }

    //basket page
    .basket-donation {
        .panel-product {
            form {
                div.pull-right {
                    float: none !important;

                    > div {
                        width: 120px;

                        input {
                            max-width: 120px;
                        }
                    }
                }

                div.gift-aid {
                    float: none !important;
                }
            }
        }
    }

}