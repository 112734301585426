.panel-breadcrumbs {
  .panel-body {
    padding: 5px;

    .breadcrumb {
      margin: 0;
      padding: 0;
      background: none;

      > li {
        margin-bottom: 0;
        + li::before {
          //content: "\f0da";
          //font-family: FontAwesome;
          vertical-align: middle;
          color: $text-color;
          padding: 0 0 0 5px;
        }

        a:hover {
          text-decoration: underline;
          background: transparent;
        }
      }

      .btn-group .dropdown-toggle {
        box-shadow: none;
      }

      .dropdown-menu {
        max-height: 30vh;
        overflow: auto;
        left: -7px;
        margin-top: 5px;
        box-shadow: 0 7px 7px rgba(0, 0, 0, 0.176);
        border-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        min-width: calc(100% + 10px);

        > li > a {
          padding: 3px 12px;
        }
      }

      .btn {
        background: transparent;
        border: 0;
        padding: 5px;
      }
    }
  }
}
