.panel-availability {

  h2 {
    font-size: $font-size-base + 4px;
    border-bottom: 1px solid $light-grey;
    padding-bottom: 4px;
    margin: 15px 0 5px 0;

    &:first-child {
      margin-top: 0;
    }
  }

  .availability {
    font-size: 13px;
    padding: 2px 6px;
    color: #fff;
  }

  p {
    margin-left: 0px;
  }
}


.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;

  .form-inline > .row:nth-child(odd) {
    background: #f4f4f4;
  }

  .form-inline > .row.has-error {
    background: lighten($brand-danger, 30%);
  }

  .form-inline > .row.has-error:nth-child(odd) {
    background: lighten($brand-danger, 25%);
  }
}

@media (min-width: $screen-lg-min) {
  .modal-container {
    max-width: 1170px;
  }
}

.modal-header h3 {
  margin-top: 0;
}

.vue-modal-body {
  margin: 20px 0;

  .container {
    max-height: 60vh;
    overflow: auto;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .container {
      width: 100%;
    }
  }

  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    .container {
      width: 100%;
      max-width: 95vw;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .modal-container {
    overflow-y: auto;
    max-height: 100vh;

    .form-group {
      margin-bottom: 5px;
    }
  }
}

.modal-default-button {
  float: right;
}

/*
 * the following styles are auto-applied to elements with
 * v-transition="modal" when their visiblity is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter, .modal-leave {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.form-control-strut {
  height: 34px;
  display: inline-block;
  vertical-align: middle;
  width: auto;
}

.modal-container {
  label + input,
  label + select {
    margin-left: 6px;
  }

  label {
    font-weight: 300;
  }

  .title-row {
    font-weight: 500;
  }

  .form-control, .form-control-strut {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .modal-quantity {
    width: 42px;
    display: inline-block;
  }

  @media (max-width: $screen-xs-max) {
    .checkbox {
      display: inline-block;
    }
  }
}

.fc-past {
  background-color: $light-grey;
}

.fc-event-other-month {
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.fc-event-container:hover {
  .fc-event-other-month {
    opacity: 1;
  }
}