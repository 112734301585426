footer {
  background: #eaeaea;
  padding: 50px 0 35px 0;
  position: relative;
  //min-height:307px;

  border-top: 1px solid #aaa;

  &:after {
    box-shadow: inset 0 0 10em #999;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    content: "";
  }

  a {
    color: #464e54;
  }

  div {
    z-index: 3;
    position: relative;
  }

  .copy-row {
    p {
      line-height: 33px;
      margin: 0;
    }
  }

  ul {
    margin: 0;
    li + li {
      margin-top: 5px;
    }

    &.bump {
      margin-top: 28px;
    }
  }

  hr {
    margin-top: 15px;
    margin-bottom: 10px;
    border-color: #aaa;
  }

  .worldpay {
    position: absolute;
    bottom: 0;
    right: 15px;
    padding: 7px 12px;
    box-shadow: 0 0 5px 5px #ffffff;
    background: #ffffff;
  }

  @media (max-width: $screen-sm-max) {
    //padding: 20px 0 15px 0;
  }
}
