.video {
  width: 100%;
  height: 100%;
  position: relative;
}

.video-overflow {
  width: 100%;
  overflow: hidden;
  height: 100%;
  position: relative;
}

video {
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
  position: absolute;
  bottom: 0;
}

.video-text {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;

  color: #fff;
  text-align: left;

  .video-text-spacer {
    float: none;
    display: table-cell;
  }

  .video-text-inner {
    display: table-cell;
    background-color: rgba(0,0,0,0.5);
    float: none;
    vertical-align: middle;
  }

  .container {
    margin-top: -200px;
    position: relative;
    top: 40%;
  }

  .title {
    //padding-top: 100px;

    h1 {
      font-weight: 200;
      font-size: 50px;
    }

    h2 {
      margin: 0;
      font-size: 30px;
    }

    font-size: 22px;
    font-weight: 300;
  }

  .video-warning-message {
    background: $gold;
    color: white;
    padding: 1em 1.5em;
    margin-top: 1.5em;

    a {
      color: white;
      text-decoration: underline;
    }
  }

  h2 {
    margin-top: 50px;
    font-size: 25px;
    font-weight: 300;
  }

}

@media (max-width: $screen-md-max) {
  .video-text {
    .title {
      font-size: 18px;
      h1 {
        font-size: 38px;
      }

      h2 {
        font-size: 26px;
      }
    }

    .search-option-row  {
      margin-top: 40px;
    }
  }
}

@media (max-width: $screen-xs-max) {

  .video {
    height: auto;
    background-position: 43% center;
    background-image: url('/images/fisherman.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .video-overflow {
    display: none;
  }

  .video-text {
    height: auto;
    position: relative;
    padding-top: 0;

    .container {
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 0;
      top: 0;
    }

    .video-text-inner {
      padding-bottom: 25px;
    }

    .title {
      padding-top: 30px;
      h1 {
        font-size: 38px;
      }

      h2 {
        font-size: 21px;
      }
    }
  }
}

.row {
  &.reports {
    text-align: center;

    .clearfix {
      margin-bottom: 20px;
    }

    .image {
      background-size: cover;
      background-repeat: no-repeat;
      height: 220px;
      position: relative;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      max-width: 263px;
      margin: 0 auto;

      .caption {
        background: none repeat scroll 0 0 rgba(0, 0, 0, 0.6);
        bottom: 0;
        left: 0;
        margin: 0;
        position: absolute;
        width: 100%;
        min-height: 74px;
        display: block;
        padding: 5px 5px;

        transition: padding 0.3s;

        -webkit-border-bottom-right-radius: 6px;
        -webkit-border-bottom-left-radius: 6px;
        -moz-border-radius-bottomright: 6px;
        -moz-border-radius-bottomleft: 6px;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;

        .caption-row {
          width: 100%;
          display: table;
          height: 42px;

          h3 {
            font-size: 16px;
            font-weight: 400;
            margin: 0;
            display: table-cell;
            vertical-align: middle;
            line-height: 21px;
            color: #fff;
          }
        }

        p {
          font-size: 14px;
          margin: 0;
          line-height: 25px;
          color: #fff;
        }
      }
    }

    a:hover .image .caption {
      padding-bottom: 10px;
    }

    @each $type in (trout),(salmon),(sea-trout),(coarse) {
      .#{$type}-image {
        background-image: url('/images/#{$type}.jpg');
      }
    }
  }
}

.index-img {
  margin-top: 52px;
}

@media (max-width: $screen-sm-max) {
  .index-img {
    margin-top:10px;
  }
}


.farlows-trophy {
  display: inline-block;
  margin-bottom: 1em;

  img {
    width: 100%;
  }
}

.section.enews {
  h4 {
    font-size: 2em;
    text-align: center;

    a {
      display: block;
      font-size: 0.8em;
      margin-top: 0.5em;
    }
  }
}

#salmon-weight-calculator {
  h3 {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .result {
    font-weight: bold;
    margin: 0.75em 0;
    text-align: center;
  }

  .logos {
    display: flex;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      max-width: 120px;
    }
  }
}
