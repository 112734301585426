.paragraph-list {

    .paragraph-list-sort-container > div {
        padding-bottom: 0.75em;

        &:last-child {
            border-bottom: none;
            padding-bottom: none;
        }

        > .paragraph {
            > h3 {
                a {
                    float: right;
                }
            }
    
            .drag-handle {
                margin-right: 0.5em;
            }
        }

        > .insert {
            border-top: 1px solid mix($light-grey, $dark-grey);
            border-bottom: 1px solid mix($light-grey, $dark-grey);
            margin: 1.5em 0 1em;

            > p {
                display: flex;
                margin: 15px 0;

                a {
                    margin-left: 15px;
                }
            }
        }
    }

    .paragraph-tools {
        margin-bottom: 1em;
    }
    

    .template-paragraph-double-image {
        display: flex;
        justify-content: space-between;

        > div {
            flex-basis: 49%;
        }
    }

    .template-paragraph-triple-image {
        display: flex;
        justify-content: space-between;

        > div {
            flex-basis: 32%;
        }
    }

    .template-paragraph-embedded-video {

        .video-input-with-thumbnail {
            display: flex;
             > div {
                &:first-child {
                    flex-basis: auto;
                    flex-grow: 1;

                    display: flex;
                    align-items: center;
                }

                &:last-child {
                    flex-basis: 33%;
                }
             }
        }

        input {
            width: 100%;
            max-width: 25em;
        }
    }

    .dropzone {
        .file-details {
            p {
                display: flex;
            }

            label {
                margin-right: 0.5em;
            }

            input {
                flex-grow: 1;
                max-height: 2.1em;
            }
        }
    }

    .template-paragraph-text-with-image {
        .wrap-option {
            margin: 1em 0;
        }
    }
}

