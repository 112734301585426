#webcam-viewer {
    text-align: center;
    margin-bottom: 1.5em;

    img {
        width: 100%;
        height: auto;;
    }

    //this webcam has strange image scaling issue, attempt to correct with css
    &.webcam-viewer-llangurig {
        img {
            transform: scaleY(0.75);
        }
    }
}

.webcam-static-images {
    margin-top: 1em;
    margin-bottom: 1em;
    > div {
        text-align: center;
        img {
            width: 100%;
        }
    }
}

.webcam-provider {
    text-align: center;
    margin-top: 1em;

    img {
        max-width: 300px;
        width:100%;
    }
}

.img-advert-banner {
    margin-bottom: 1.5em;
    width: 100%;
}

.webcam-temperature-week {
    margin-bottom: 1em;

    display: none;

    &.show {
        display: block;
    }
}