@import "app/variables";

@import "bootstrap";
@import "font-awesome";
@import url(//fonts.googleapis.com/css?family=Source+Sans+Pro:400,200,300,600);

@import "app/bootstrap-variables";

@import "vendor/sweetalert";
@import "vendor/fullcalendar";
@import "vendor/calendar";
  
@import "vendor/fancybox";
@import "toastr.scss";
@import "loaders";
//@import "vendor/chosen";

@import "bootstrap-chosen-variables";
@import "bootstrap-chosen";

tr.has-error {
  td, th {
    background-color: #f2dede;
  }
}

@import "app/navbar";
@import "app/footer";

@import "app/index";
@import "app/beat";
@import "app/calendar";

@import "app/breadcrumb";

@import "app/paragraphs";

/* Tidy Me Up Plz */

html, body {
  height: 100%;
  width: 100%;
  font: {
    family: $font-family;
    size: 16px;
  }
  color: $text-color;
  background-color: #fbfbfb;
}

body {
  padding-top: $navbar-height;
}

@import "app/section";

/*
  Bootstrap Equal Column Height
*/
@media (min-width: $screen-sm-min) {
  .row-eq-height {
    display: flex;
    flex-wrap: wrap;

    > [class*='col-'] {
      display: block;
      flex-direction: column;
    }

    &.row:after,
    &.row:before {
      display: flex;
    }
  }

  .contact-us-sidebar {
    height: 100%;

    > div:first-child {
      margin-bottom: 15px;
      height: 386px;
    }

    > div:last-child {
      height: calc(100% - 386px - 15px);
    }
  }
}

.row-close {
  margin-left: - $grid-gutter-width / 4;
  margin-right:  - $grid-gutter-width / 4;

  > [class*='col-'] {
    padding-left: $grid-gutter-width / 4;
    padding-right: $grid-gutter-width / 4;
  }
}

.text-small {
  font-size: 14px;
  line-height: 20px;
}

.td-min {
  width: 1%;
}

.pagination-row {

  p {
    margin: 0;
    line-height: 35px;
  }

  .pagination-container {
    text-align: center;

    .table + & {
      margin-top: -20px;
    }
  }
}

@import "app/banner";

span.fishing-type-key {
  border-radius: 50%;
  border: 2px solid rgba(0,0,0,.7);
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 2px;
  cursor: pointer;
  position: relative;
  top: -2px;
}

@import "app/beat-filter";

@import "app/carousel";

.gallery {
  text-align: center;
  padding: 20px;
  .item {
    height: 200px;
    width: 200px;
    margin: 7px;
    background-size: cover;
    display: inline-block;
  }
}

@import "app/admin";
@import "app/admin/availability";
@import "app/admin/paragraphs";

@import "app/form";
@import "app/admin-form";

@import "app/modal";
@import "app/catch-return";
@import "app/webcam";

@import "App/blog";

@media (min-width: $screen-md-min) {
  .border-right-md {
    border-right: 1px solid #e2e2e2;
  }
}

@media (max-width: $screen-sm-max) {
  .border-right-md {
    border-bottom: 1px solid #e2e2e2;
    margin-bottom: 10px;
  }
}

.gold {
  color: $gold;
}

[v-cloak] {
  display: none!important;
}

@import "app/dropzone";
@import "app/map";

@import "app/basket";

.table {
  display: table;
  margin: 0;
}

.table-fixed {
  display: table;
  table-layout: fixed;
  margin: auto;
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
  vertical-align: top;
  &.middle {
    vertical-align: middle;
  }
}

.table-cell-fixed {
  width: 1px;
}

.undecorated:hover {
  text-decoration: none;
}

.space-top {
  margin-top: 25px !important;
}

.space-bottom {
  margin-bottom: 25px !important;
}

.table-click {
  tr:hover {
    cursor: pointer;
  }
}

.alert-titled {
  b {
    display: block;
    padding-bottom: 3px;
    //&:before {
    //  display: inline-block;
    //  font-family: "FontAwesome";
    //  padding-right: 8px;
    //}
  }
}

abbr[title] {
  text-decoration: none;
}


//.alert-info.alert-titled b:before {
//  content: "\f05a";
//}
//
//.alert-danger.alert-titled b:before {
//  content: "\f06a";
//}
//
//.alert-success.alert-titled b:before {
//  content: "\f058";
//}

@import "app/toast";

.table.table-fp {
  &.table-hover {
    tr:hover > td {
      background-color: lighten($gold, 40%);
    }
  }

  tbody > tr.active > td {
    background-color: lighten($gold, 35%);
  }
}

h1,h2,h3,h4,h5 {
  &.header-simplified {
    text-shadow: none;
    small {
      font-weight: 100;
    }
  }
}

h2.anchor {
  margin-top: (-$banner-collapsed-height - $navbar-height);
  padding-top: ($banner-collapsed-height + $navbar-height + 20px);
}

.bg-gold {
  background-image: -ms-radial-gradient(center, ellipse farthest-corner, #DAE036 0%, #E0B709 100%);
  background-image: -moz-radial-gradient(center, ellipse farthest-corner, #DAE036 0%, #E0B709 100%);
  background-image: -o-radial-gradient(center, ellipse farthest-corner, #DAE036 0%, #E0B709 100%);
  background-image: -webkit-gradient(radial, center center, 0, center center, 286, color-stop(0, #DAE036), color-stop(100, #E0B709));
  background-image: -webkit-radial-gradient(center, ellipse farthest-corner, #DAE036 0%, #E0B709 100%);
  background-image: radial-gradient(ellipse farthest-corner at center, #DAE036 0%, #E0B709 100%);
}

.loading {
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255, 0.9);
    z-index: 8;
  }

  > .loading-inner {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 9;
    margin-top: -20px;

    &:after {
      content: 'Loading';
      margin-top: 20px;
      color: $dark-grey;
      font-size: 30px;
      display: block;
      position: absolute;
      text-align: center;
      width: 100%;
    }

    > .loader-inner {
      width: 0;
      display: inline-block;
      > div {
        background-color: $dark-grey;
      }
    }

    &.loading-top {
      top: 85px;
    }
  }

  &.loading-absolute {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.stats-container {
  position: relative;
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &:after {
      background-color: rgba(0, 0, 0, .4);
      content: "";
    }

    > .loading-inner {
      &:after {
        content: "";
      }

      .loader-text {
        margin-top: 20px;
        color: $dark-grey;
        font-size: 30px;
        display: block;
        position: absolute;
        text-align: center;
        width: 100%;
      }
    }
  }
}

.list-inline.dotted {
  display: inline;
  &:after {
    display: block;
    content: '';
    clear: both;
  }
  > li {
    padding-left: 0;
    padding-right: 20px;
    float: left;
    &:after {
      content: '\25CF';
      font-size: 80%;
      display: inline-block;
      color: #fff;
      padding-left: 20px;
      float: right;
      padding-top: 3px;
      opacity: 0.8;
    }

    &:last-child {
      padding-right: 0;
      &:after {
        display: none;
      }
    }
  }
}

.btn-passport {
  @include button-variant($btn-passport-color, $btn-passport-bg, $btn-passport-border);
}

.btn-yes {
  color: $btn-yes-color;
  background-color: $btn-yes-bg;
  border-color: $btn-default-border;

  &:focus,
  &.focus {
    background-color: darken($btn-success-bg, 10%);
    color: #fff;
    border-color: darken($btn-default-border, 25%);
  }
  &:hover {
    background-color: darken($btn-success-bg, 10%);
    color: #fff;
    border-color: darken($btn-default-border, 12%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-color: darken($btn-success-bg, 10%);
    color: #fff;
    border-color: darken($btn-default-border, 12%);

    &:hover,
    &:focus,
    &.focus {
      background-color: darken($btn-success-bg, 17%);
      color: #fff;
      border-color: darken($btn-default-border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $btn-yes-bg;
      border-color: $btn-default-border;
    }
  }

  .badge {
    color: $btn-yes-bg;
    background-color: $btn-yes-color;
  }
}

.btn-no {
  color: $btn-no-color;
  background-color: $btn-no-bg;
  border-color: $btn-default-border;

  &:focus,
  &.focus {
    background-color: darken($btn-danger-bg, 10%);
    color: #fff;
    border-color: darken($btn-default-border, 25%);
  }
  &:hover {
    background-color: darken($btn-danger-bg, 10%);
    color: #fff;
    border-color: darken($btn-default-border, 12%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-color: darken($btn-danger-bg, 10%);
    color: #fff;
    border-color: darken($btn-default-border, 12%);

    &:hover,
    &:focus,
    &.focus {
      background-color: darken($btn-danger-bg, 17%);
      color: #fff;
      border-color: darken($btn-default-border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $btn-no-bg;
      border-color: $btn-default-border;
    }
  }

  .badge {
    color: $btn-no-bg;
    background-color: $btn-no-color;
  }
}

.comma-delimited {
  > span {
    &:empty {
      display: none;
    }
    &:not(:first-child) {
      margin-left: -.1em;
    }
    &:not(:first-child):before {
      content: ", ";
    }
  }
}

.band {
  padding: 30px 0;
  margin: 30px 0;
}
.grey-band {
  background-color: $light-grey;
}

.content {
  padding-top: $content-padding;
  padding-bottom: $content-padding;
  min-height: calc(100vh - #{$banner-col-height + $navbar-height});

  > .container:first-child,
  .container-fluid:first-child {
    h2:first-child {
      //margin-top: 0;
    }
  }

  h2, h3, h4 {
    font-weight: 200;
    text-shadow: 1px 1px 2px #aaa;
  }

}

.banner-header.affix + .content {
  padding-top: $banner-col-height + $content-padding;
  min-height: calc(100vh - #{$navbar-height});

  @media (max-height: $screen-short) {
    padding-top: $banner-col-short-height + $content-padding;
  }
}

.navbar-obs-admin + .banner-header.affix + .content {
  //padding-top: $banner-col-height + $content-padding + $admin-navbar-height;

  @media (max-height: $screen-short) {
    //padding-top: $banner-col-short-height + $content-padding + $admin-navbar-height;
  }
}

.popover {
  max-width: 25vw;
  min-width: 276px;

  .popover-content {
    width: 100%;
    height: 100%;
    img {
      min-height: 100%;
      min-width: 100%;
    }
  }
}

.panel-rules {
  margin-top: 20px;
}

.btn > p {
  margin: 0;
}

.option-box {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
  border: 1px solid #dadfd3;
  padding: 3px;
  background-color: #fff;
  max-width: 380px;
  margin: 15px auto;
  font-size: 0;

  .option-image {
    text-align: center;
    height: 210px;
    background-position: center;
    background-size: cover;
    filter: none;
    -webkit-filter: grayscale(0%);
    -webkit-transition: filter .5s , -webkit-filter .5s;
    -moz-transition: filter .5s , -webkit-filter .5s;
    transition: filter .5s , -webkit-filter .5s;

    &.option-beat {
      background-image: url('/images/search/beat.jpg');
    }

    &.option-area {
      background-image: url('/images/search/area.jpg');
    }

    &.option-date {
      background-image: url('/images/search/date.jpg');
    }

    &.option-type {
      background-image: url('/images/search/type.jpg');
    }

    &.option-passport {
      background-image: url('/images/info/passport2014.jpg');
      background-size: contain;
      background-repeat: no-repeat;
    }

    &.option-fish {
      background-image: url('/images/info/fish.jpg');
    }

    &.option-biosecurity {
      background-image: url('/images/info/biosecurity.jpg');
    }

    &.option-canoeing {
      background-image: url('/images/info/canoeing.jpg');
    }

    &.option-environment-agency {
      background-image: url('/images/info/environment-agency.jpg');
      background-size: contain;
      background-repeat: no-repeat;
    }

    &.option-wales {
      background-image: url('/images/info/wales.jpg');
      background-size: contain;
      background-repeat: no-repeat;
    }

    &.option-salmon {
      background-image: url('/images/info/salmon.jpg');
    }

    &.option-trout {
      background-image: url('/images/info/trout.jpg');
    }

    &.option-grayling {
      background-image: url('/images/info/grayling.jpg');
    }

    &.option-coarse {
      background-image: url('/images/info/coarse.jpg');
    }

    &.option-what-to-do {
      background-image: url('/images/info/what-to-do.jpg');
      background-size: contain;
      background-repeat: no-repeat;
    }

    &.option-food-species {
      background-image: url('/images/info/food-species.jpg');
      background-size: contain;
      background-repeat: no-repeat;
    }

    img {
      width: 100%;
    }
  }

  .option-text {
    text-align: center;
    h3 {
      font-size: 17px;
      font-weight: 400;
      letter-spacing: 1px;
      text-shadow: none;
    }
    &:after {
      height: 2px;
      width: 11%;
      content: "";
      margin: 20px auto 10px;
      background-color: $gold;
      display: block;
      -webkit-transition: all .5s;
      -moz-transition: all .5s;
      transition: all .5s;
    }
  }

  &.inline {
    .option-image {
      display: inline-block;
      height: 80px;
      text-align: center;
      width: 80px;
    }
    .option-text {
      display: inline-block;
      margin-left: 10px;
      vertical-align: top;
      width: calc(100% - 90px);
      h3 {
        font-size: 15px;
      }
    }
  }

  &:hover {
    .option-image {
      -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
      filter: gray;
      filter: grayscale(100%);
    }

    .option-text {
      &:after {
        width: 21%;
        height: 3px;
        margin-top: 19px;
      }
    }
  }
}

.title-space-bottom {
  margin-bottom: 40px;
}

a.plain {
  color: $text-color;
  &:hover, &:focus {
    text-decoration: none;
  }
}

.beat-slug {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
  border: 1px solid #dadfd3;
  padding: 15px 10px 0;
  background-color: #fff;
  margin-bottom: 15px;
  transition: background-color 0.3s;
  position: relative;
  .beat-title {
    pointer-events: none;
  }

  &.active:after {
    font-family: FontAwesome;
    content: "\f058";
    color: $brand-success;
    display: block;
    position: absolute;
    top: 4px;
    right: 9px;
    font-size: 19px;
  }

  &.legend {
    cursor: default;
    .legend-key {
      height: 4px;
      margin-left: -10px;
      margin-right: -10px;
      margin-top: 11px;
    }

    .legend-keyless {
      height: 0;
    }
  }

  .keys {
    height: 4px;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 11px;
    .key {
      height: 4px;
      width: 20%;
      float: left;
      opacity: 0.7;
      transition: opacity 0.3s;
    }
  }

  &.clickable {
    cursor: pointer;
    &:hover {
      background-color: $light-grey;

      .keys .key {
        opacity: 1;
      }
    }
  }

  &.beat-slug-sm {
    padding-top: 8px;
    margin-bottom: 10px;

    &.legend .legend-key {
      margin-top: 7px;
    }
  }
}

a:hover .beat-slug {
  background-color: $light-grey;

  .keys .key {
    opacity: 1;
  }
}

label.beat-slug {
  display: block;
  padding-top: 10px;
  margin-bottom: 10px;

  input {
    display:none;

    &:checked + span:after {
      font-family: FontAwesome;
      content: "\f058";
      color: $brand-success;
      display: block;
      position: absolute;
      top: 4px;
      right: 9px;
      font-size: 19px;
    }
  }
}

.panel-body {
  > .beat-slug,
  > .beat-slug-sm {
    margin-top: 0;
    margin-bottom: 0;
  }

  > .beat-slug + .beat-slug {
    margin-top: 15px;

    &.beat-slug-sm {
      margin-top: 10px;
    }
  }
}

.panel-warning > .panel-heading {
  .text-muted {
    color: lighten(#8a6d3b, 40%);
  }
}

.area-header {
  height: 150px;
}

h1, h2, h3 {
  > small {text-shadow: none;}
}

.fa-left {
  margin-right: 8px;
}

.fc-view {
  background-color: #fff;
}

.no-top {
  margin-top: 0;
}

.clickable {
  cursor: pointer;

  &.hover:hover {
    background-color: $light-grey !important;
  }
}

.row-striped > div.row:nth-of-type(2n) {
  background-color: #f9f9f9;
}

.beat-search {
  margin-bottom: 15px;
}

.hide-block {
  opacity: 0 !important;
}

.anchor {
  margin-top: (-$banner-collapsed-height - $navbar-height - 10px);
  padding-top: ($banner-collapsed-height + $navbar-height + 10px);
}

.center-images {
  text-align: center;
  font-size: 0;

  > .img-responsive,
  div > .img-responsive {
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: $screen-xs-max) {
    > .img-responsive,
    div > .img-responsive {
      display: inline-block;
      margin: $gap / 2;
    }
  }
}

.panel-wild-stream {
  float: right;
  margin-bottom: 15px;
  margin-left: 15px;

  @media (max-width: $screen-xs-max) {
    float: none;
    width: 100%;
    margin-left: 0;
  }

  .btn {
    white-space: inherit;
  }
}

.xs-date {
  margin-right: 10px;
  margin-left: 10px;
}

@each $dir in $trbl {
  .gap-#{$dir} {
    margin-#{$dir}: $gap;
  }

  .gap-#{$dir}-x2 {
    margin-#{$dir}: $gap*2;
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  @each $dir in $trbl {
    .no-padding-#{$dir}-sm {
      padding-#{$dir}: 0;
    }
  }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  @each $dir in $trbl {
    .no-padding-#{$dir}-md {
      padding-#{$dir}: 0;
    }
  }
}

@media (min-width: $screen-lg-min) {
  @each $dir in $trbl {
    .no-padding-#{$dir}-lg {
      padding-#{$dir}: 0;
    }
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .container {
    width: 100%;
    max-width: 850px;
  }
}

.img-product {
  max-height: 67px;
  margin-right: 15px;
}

.brand-svg {
  path, circle {
    fill: $gold;
  }
}

.panel-product {
  input.price {
    max-width: 80px;
  }

  .form-inline .form-control {
    display: inline-block;
  }

  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }

  .form-inline.pull-right {
    div.pull-right {
      font-size: 0;
      span.label-qty {
        font-size: 16px;
        vertical-align: middle;
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    .form-inline.pull-right {
      float: none !important;
      width: 100%;
      overflow: auto;

      &::after {
        clear: both;
      }

      & div.pull-right::after {
        clear: both;
      }

      & + span {
        margin-top: 10px;
      }
    }
  }

  .gift-aid {
    float: right;
    margin-top: 0px;
    label {
      padding-top: 5px;
      input[type=checkbox] {
        margin-right: 6px;
        position: relative;
        top: 2px;
        @media (max-width: $screen-xs-max) {
          top: 4px;
        }
      }
      span {
        font-size: 14px;
      }
    }
  }


  @media (max-width: $screen-xs-max) {
    .label-qty {
      margin-left: 0;
    }
    input.price {
      max-width: 50px;
    }

    .gap-right {
      margin-right: 10px;
    }
  }
}

.icon-key {
  svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
  p {
    display: inline-block;
    margin-bottom: 15px;
  }
  > div {
    margin-top: 15px;
  }
}

.label-qty {
  margin-right: 10px;
  margin-left: 15px;
}

.alert {
  > h3 {
    margin-top: 0;
    text-shadow: none;
    font-weight: 400;
  }

  a:not(.btn) {
    color: #fafafa;
    padding-bottom: 1px;
    border-bottom: 1px solid #fff;
    &:hover, &:active, &:focus {
      text-decoration: none;
      color: #fff;
    }
  }
}

// Weird padding and 0 width is to stop it underlining on hover when used
//in a link
.separator {
  padding: 0 10px 0 5px;
  &:after {
    content: "/";
    font-weight: 600;
    display: inline-block;
    width: 0;
  }
}

ul {
  > li {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.notices {
  .time {
    font-weight: bold;
    display: inline-block;
    width: 80px;
    left: -80px;
    position: absolute;
    top: 0;
  }
  p {
    margin-left: 80px;
    position: relative;
    margin-bottom: 0;
  }

  h3 {
    font-size: 16px;
    margin-bottom: 2px;
  }
}

.dismiss-suggestion {
  position: relative;
  right: -8px;
  top: -12px;
}

.btn-salmon-catches {
  background-image: url('/images/salmon-catches-button.jpg');
  min-height: 114px;
  position: relative;
  padding: 20px;
  text-align: center;
  background-size: cover;

  @media (max-width: $screen-xs-max) {
    margin-bottom: 15px;
  }

  &:hover {
    box-shadow: 0 0 15px #888888 inset;
    cursor: pointer;
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.41);
    position: absolute;
    top: 0;
    left: 0;
  }

  p {
    color: #fff;
    text-shadow: 1px 1px 2px #222;
    z-index: 1;
    position: relative;
    font-size: 20px;
  }

  p + p {
    font-size: 16px;
    margin-bottom: 0;
  }
}

.date-grid {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */

  & > tbody + tbody {
    border-top: 1px;
  }

  & > thead > tr > th,
  & > thead > tr > td {
    border-bottom-width: 1px;
  }

  td.past {
    background: #f2f2f2;
  }

  td.today {
    background: #fcf8e3;
  }

  tr.days {
    th {
      text-align: center;
      padding: 0;
      width: 10%;

      &:first-child {
        width: 30%;
        border-bottom: 0;
      }
    }
  }

  tr.numbers {
    td {
      text-align: right;
      padding: 2px;

      &:first-child {
        border-top: 0;
      }
    }
  }

  tr.events {
    height: 100%;
    td {
      padding: 0;

      &.selected {
        background: rgba(188,232,241,.5);

        span {
          opacity: 0.6;
        }
      }

      span {
        pointer-events: none;
      }

      &.extra {
        opacity: 0.85;

        &:hover {
          opacity: 1;
        }
      }
    }

    th {
      padding: 0;
      height: 75px;
      position: relative;

      div {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      a {
        background-size: cover;
        display: block;
        //min-height: 75px;
        height: 100%;
        padding: 0 4px;
        text-align: center;

        span {
          display: inline-block;
          position: relative;
          text-align: center;
          top: 50%;
          transform: translateY(-50%);
          font-weight: 200;
          font-size: 20px;
          z-index: 1;
          color: $text-color !important;
          text-shadow: 1px 1px 2px $text-color;
        }

        &.bg-image {
          &::after {
            background-color: rgba(0, 0, 0, 0.5);
            content: "";
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
          }
          span {
            color: #fff !important;
            text-shadow: 1px 1px 2px #aaa;
            font-weight: 300;
          }
        }
      }

      // IE 10-11
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        vertical-align: middle;

        div {
          position: relative;
          height: auto;
        }

        a {
          background: none !important;

          span {
            top: 0;
            transform: none;
          }

          &.bg-image {
            &::after {
              content: none;
            }
            span {
              color: $text-color !important;
              text-shadow: 1px 1px 2px $text-color;
            }
          }
        }
      }

      // Edge
      @supports (-ms-ime-align:auto) {
        vertical-align: middle;

        div {
          position: relative;
          height: auto;
        }

        a {
          background: none !important;

          span {
            top: 0;
            transform: none;
          }

          &.bg-image {

            &::after {
              content: none;
            }

            span {
              color: $text-color !important;
              text-shadow: 1px 1px 2px $text-color;
            }
          }
        }
      }
    }
  }

  .grid-event {
    font-size: 13px;
    padding: 1px 2px;
    color: #fff;
    display: block;
    margin: 1px;
    line-height: 1.3;

    i.fa {
      margin-left: 1px;

      &.fa-fw {
        width: 12px;
      }
    }
  }

  tbody:last-child {
    tr.numbers,
    tr.days {
      display: none;
    }
  }
}

//accommodations
.accommodation {
  .sidebar.affix {
    top: 169px;

    @media (min-width: $screen-lg-min) {
      width: 263px;
    }

    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      width: 293px;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      width: 253px;
    }

    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }

  #sidebar {
    .nav > li {

      &.active {
        padding-left: 5px;
      }

      > a {
        padding: 0;
      }
    }
  }

  .row-eq-height {
    .card {
      height: 100%;

      h3 {
        margin-top: 0;
      }

      dl.dl-horizontal {
        dt {
          width: 100px;
        }

        @media (min-width: $screen-sm-min) {
          dd {
            margin-left: 115px;
            word-wrap: break-word;
          }
        }
      }
    }
  }
}

.wild-stream-beats {
  background: #e2e2e2 none repeat scroll 0 0;
  border: 1px solid #e2e2e2;
  border-radius: 3px;
  padding: 10px 10px 10px;
  position: relative;
  margin-top: 20px;

  h3 {
    margin-top: 0;
  }
}

.card-a {
  color: $text-color;

  .card {
    position: relative;

    &:after {
      transition: opacity 0.3s;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      content: "View";
      justify-content: center;
      align-items: center;
      font-size: 25px;
      color: #fff;
      background: rgba(0,0,0,0.333);
      display: flex;
      font-family: $font-family;
    }

    &.expired {
      background: transparentize($alert-danger-bg, 0.66);
    }

    h3, p, table {
      transition: filter 0.3s;
    }
  }

  &:hover, &:focus {
    color: $text-color;
    text-decoration: none;
  }

  &:hover {
    .card {
      h3, p, table {
        filter: blur(2px);
      }
      &:after {
        opacity: 1;
      }
    }
  }
}

table.week {
  th,td {
    width: (100% / 7);
  }
}

.navbar-obs + .navbar-obs-admin.in + .banner + .accommodation {
  .sidebar.affix {
    top: 205px;
  }
}

.popover {
  border-color: #faebcc;

  .popover-title {
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b;
    text-shadow: none;
  }

  .popover-content {
    color: $text-color;

  }

  &.left > .arrow {
    border-left-color: #faebcc;
  }
}

body .buorg {
  top: $navbar-height;
  font-family: $font-family;
  background-color: $light-grey;
  border-bottom: 0;
  > div {
    padding-top: 8px;
    padding-bottom: 8px;
    > a,
    > a:visited {
      color: $link-color;
    }
    > a:hover {
      color: $link-hover-color;
    }
  }
}

.map-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
  opacity: 0.9;
  border-radius: 50%;
  background: #f0ad4e;
  border: 2px solid #ed9d2b;
  z-index: 1;
}

body #buorgclose {
  right: 10px;
  top: 5px;
}

@media only screen and (device-width: 768px) {
  .ipad-fullscreen {
    position: fixed;
    overflow: hidden;
  }
}

@media (min-width: $screen-sm-min) {
  .sidebar-padding-left {
    padding-left: 0;
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .sm-margin-top {
    margin-top: 15px;
  }
}

@media (max-width: $screen-xs-max) {
  .xs-block {
    display: block;
  }

  .xs-margin-top {
    margin-top: 15px;
  }

  .form-inline-xs {
    .form-control {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .text-right-xs {
    text-align: right;
  }
}

@media (max-width: 600px) {
  .btn-magazine-responsive {
    margin-bottom: 20px;
    width: 100%;
  }
}

@import 'app/integration';