$fa-font-path: "../../fonts/font-awesome/"; 
$icon-font-path: "../../fonts/bootstrap/";

$navbar-height: 80px;
$admin-navbar-height: 35px;
$font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Menu primarily
$screen-xl: 1480px;
$screen-xl-min: $screen-xl;
$screen-lg-max: ($screen-xl-min - 1);

// Colors
//$gold: #e0b709;
$gold: #f0ad4e;
$mandatory: red;
$dark-grey: #4D5359;
$light-grey: #f2f2f2;

$text-color: #3d3d3d;

$banner-col-sm-height: 97px;
$banner-col-height: 350px;
$banner-col-short-height: 275px;
$banner-collapsed-height: 73px;

$screen-short: 820px;

$border-radius-base: 3px !default;
$alert-padding: 15px 15px 15px 55px !default;

$alert-success-bg: #51a351;
$alert-info-bg: #2f96b4;
$alert-danger-bg: #bd362f;
$alert-warning-bg: #f89406;

$alert-success-text: #ffffff;
$alert-info-text: #ffffff;
$alert-danger-text: #ffffff;
$alert-warning-text: #ffffff;

$nav-link-hover-bg: transparent;

$btn-passport-color:              #fff;
$btn-passport-bg:                 darken($gold, 2%);
$btn-passport-border:             darken($btn-passport-bg, 5%);

$gap: 15px;

$trbl: top right bottom left;

$content-padding: 50px;

$banner-affix-height: $banner-collapsed-height + $navbar-height - $banner-col-height;
$banner-affix-short-height: $banner-collapsed-height + $navbar-height - $banner-col-short-height;