.fc-toolbar button {
  background-color: #fff;
  color: #333;
  -moz-user-select: none;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857;
  margin-bottom: 0;
  padding: 6px 12px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  height: auto;

  &:hover {
    background-color: #e6e6e6;
    border-color: #adadad;
    color: #333;
  }
  &.fc-prev-button {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  &.fc-next-button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.fc-event {
  //padding: 3px 5px;
  border: 0;
  margin: 1px;
  padding: 0;
  border-radius: 0;
  pointer-events: none;

  i.fa {
    margin-left: 1px;
    &.fa-fw {
      width: 12px;
    }
  }

  .cal-av {
    //background: linear-gradient(to right, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0.6) 40%, rgba(255,255,255,0.1) 100%);
    padding: 3px 5px;
  }
}

.fc-icon:after {
  margin: 0 0;
  color: #333;
}

.fc-month-select {
  display: inline-block;
  width: auto;
  min-width: 100px;
  position: relative;
  z-index: 1;

  @media (max-width: $screen-xs-max) {
    display: none;
  }
}